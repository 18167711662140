// normalize CSS across browsers
require('./src/normalize.css');
// custom CSS styles
require('./src/bootstrap-custom.css');
require('./src/style.css');

// Highlighting for code blocks
require('prismjs/themes/prism.css');

exports.shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const currentPosition = getSavedScrollPosition(location);
  setTimeout(() => { window.scrollTo(...(currentPosition || [0, 0])); }, 100);

  return false;
};
